<template>
  <v-content
    id="pages"
    :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'"
  >
    <v-img
      :class="{
        'v-image--sm': this.$vuetify.breakpoint.smAndDown,
        'v-image--md': this.$vuetify.breakpoint.mdAndDown
      }"
      src="https://scontent-otp1-1.xx.fbcdn.net/v/t31.0-8/21246219_1209104472567511_5121227584155891629_o.jpg?_nc_cat=111&ccb=2&_nc_sid=e3f864&_nc_ohc=Lq2gs2XqNUMAX_6Kil0&_nc_ht=scontent-otp1-1.xx&oh=5043b4b9926787dd3903410013f93b96&oe=6033281F"
      gradient="to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)"
      max-height="100vh"
    >
      <v-responsive
        :style="styles"
        min-height="100vh"
        class="d-flex align-center"
      >
        <router-view />
      </v-responsive>
    </v-img>
  </v-content>
</template>

<script>
  export default {
    name: 'PagesCoreView',

    data: () => ({
      srcs: {
        '/pages/lock': 'lock.jpg',
        '/pages/login': 'login.jpg',
        '/pages/pricing': 'pricing.jpg',
        '/pages/register': 'register.jpg',
      },
    }),

    computed: {
      src () {
        return this.srcs[this.$route.path]
      },
      styles () {
        const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 175 : 100
        const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 175 : 150
        return {
          padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
        }
      },
    },
  }
</script>
